<!--
 * @FileDescription: 自定义LineWrapper组件
 * @Author: 朱建波
 * @Date: 2023-11-27
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZLineWrapper
 *
 * @Props
 * @property {Boolean} list                = [true|false]   是否显示为列表形式，默认为 false
 * @property {Boolean} vertical            = [true|false]   是否换行，默认为 false
 * @property {Boolean} reverse             = [true|false]   是否标签和内容反色，默认为 false
 * @property {Number} primary              = [true|false]   是否内容显示主题色，默认为 false
 * @property {Number} bold                 = [true|false]   是否内容加粗，默认为 false
 * @property {String} labelWidth           标签宽度
 * @property {String} labelAlign           标签内容水平对齐方式
 * @property {String} labelVerticalAlign   标签内容垂直对齐方式 默认 start
 * @property {String} labelColor           标签内容颜色
 * @property {String} color                value内容颜色
 * @property {Object} cssStyle             自定义样式
 * @property {Object} labelStyle           标签样式
 * @property {Object} valueStyle           value样式
 *
 * @Slots
 * default                                 默认内容
 *
 * @Methods
-->
<template>
  <div :class="['z-line-wrapper', vertical && 'vertical', reverse && 'reverse', primary && 'primary', bold && 'bold', labelVerticalAlign && `label-v-${labelVerticalAlign}`, list && 'list']" :style="cssStyle">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    list: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    labelWidth: { type: String, default: '' },
    labelAlign: { type: String, default: '' },
    labelVerticalAlign: { type: String, default: '' },
    labelColor: { type: String, default: '' },
    color: { type: String, default: '' },
    cssStyle: { type: Object, default: () => ({}) },
    labelStyle: { type: Object, default: () => ({}) },
    valueStyle: { type: Object, default: () => ({}) }
  },
  provide() {
    return {
      width: this.labelWidth,
      align: this.labelAlign,
      lc: this.labelColor,
      cc: this.color,
      ls: this.labelStyle,
      vs: this.valueStyle
    }
  }
}
</script>

<style lang="scss" scoped>
.z-line-wrapper {
  margin: 8rem 0;
  font-size: 16rem;
  &.list {
    margin: 0;
    display: grid;
    gap: 0 20rem;
    grid-template-columns: repeat(auto-fill, minmax(380rem, 1fr));
  }
}
</style>
